import React, { useContext, useEffect, useState } from 'react';
import { auth } from '../firebase';


const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider( {children} ) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)


    function signup(email, password) {
      return auth.createUserWithEmailAndPassword(email, password)
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function login(email, password) {
        auth.setPersistence('local')
        return auth.signInWithEmailAndPassword(email, password);

    }
    
    

    function updateEmail(email) {
      return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
      return currentUser.updatePassword(password)
    }

    function logout() {
      return auth.signOut()
    }

    useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
        setCurrentUser(user)
        setLoading(false)
    })
    return unsubscribe
    }, [])

    const value = {
        currentUser,
        signup,
        login,
        logout,
        resetPassword,
        updateEmail,
        updatePassword
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    
}