import ResponsiveAppBar from './navbar';
import Users from './getUsers';
import React, { Component } from 'react';
import Signup from './Signup';
import { Container } from 'react-bootstrap';
import { AuthProvider } from '../contexts/AuthContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import ForgotPassword from './ForgotPassword'
import UpdateProfile from './UpdateProfile'


function App() {
  return (

    <React.Fragment>
        <ResponsiveAppBar />
        <main className="container">
          <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh"}}>
          <div className="w-100" style={{ maxWidth: "400px"}} >
        <Router>
        <AuthProvider>
            <Routes>
              <Route path="/dashboard" element={<PrivateRoute />}>
                <Route exact path="/dashboard" element={<Dashboard />}/>
              </Route>
              <Route path="/update-profile" element={<PrivateRoute />}>
                <Route path="/update-profile" element={<UpdateProfile />} />
              </Route>
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Routes>
          </AuthProvider>
          </Router>
          </div>
          </Container>
        </main>
    </React.Fragment>
  );
}

export default App;
