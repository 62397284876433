import React, {Component} from 'react';
import { NavbarBrand, Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';


class NavigationBar extends React.Component {
  render() { 
    return <Navbar collapseOnSelect expand="lg" bg="danger" variant="dark">
    <Container>
    <Navbar.Brand href="#home">Unbiased Vote</Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">

      <Nav className="me-auto">
        <NavDropdown title="State Election" id="collasible-nav-dropdown-state">
          <NavDropdown.Item href="#action/3.1">Senate</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">House of Represenatives</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Governor</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Voting Locations</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Federal Election" id="collasible-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">Senate</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">House of Represenatives</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">President</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Voting Locations</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Local Election" id="collasible-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">First Selectman</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">Mayor</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Board of Finance</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Voting Locations</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <Nav>
        <Nav.Link href="/login" >Login</Nav.Link>
        <Nav.Link href="/signup">
          Sign Up
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
    </Container>
  </Navbar>;
  }
}
 
export default NavigationBar;