import React, { Component } from "react";
import { Button, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



class Users extends Component {
    state = {
        contacts: []
    }
    fetchUsers() {
        fetch("https://jsonplaceholder.typicode.com/users")
            .then(response => response.json())
            .then(data => this.setState({contacts: data }))
            .catch(error => console.log(error));
    }

    componentDidMount() {
        this.fetchUsers();
    }
    
    cardUsers = () => {
        return this.state.contacts.map(contact => {
            return (
                <Card style={{ width: '25rem', display: "flex" ,justifyContent: "right", alignItems: "right", }} key={contact.id}>
                    <Card.Body>
                        <Card.Title>{contact.name}</Card.Title>
                        <Card.Text>
                            {contact.email}
                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        });
        };

            


 

    render() {  
        return (
        <React.Fragment>
            <div className="card-container">
                {this.cardUsers()}
            </div>
        
        </React.Fragment>    
        );
    };
}

export default Users;
